import React, { useState } from "react";
import data from "./arrayData.json";
import "./App.css";

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendRequest = async (name) => {
    setLoading(true);
    setError(false);
    const rawResponse = await fetch(process.env.REACT_APP_API_URL + "dynamic-redirect", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ codeString: name }),
    });
    const content = await rawResponse.json();
    if (content.status === 200) {
      setLoading(false);
      window.open(content.redirectUrl, "_blank");
    } else {
      setLoading(false);
      setError(true);
    }
  };
  return (
    <div className="App">
      <h1>Test Activation codes</h1>

      <table>
        <tbody>
          <tr>
            <th>Names</th>
          </tr>
          {data.ARRAY &&
            data.ARRAY.length > 0 &&
            data.ARRAY.map((row, index) => {
              return (
                <tr key={row.name}>
                  <td
                    key={row.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      sendRequest(row.name);
                    }}
                    className="left-side"
                    valign="top"
                  >
                    {row.name}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {loading && <div className="loading">Loading........</div>}
      {error && <div className="error">Something Went Wrong!</div>}
    </div>
  );
}

export default App;
